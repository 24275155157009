import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createUseStyles } from "react-jss";
import Div100vh from 'react-div-100vh';
import superagent from "superagent";

import { Navbar } from "..";
import { About, SingleGallery, TiledGallery } from "../../pages"
import { theme } from "../../../config";

export const GalleryMode = {
    SINGLE: "single",
    TILED: "tiled"
};

const fileTypeRegExp = /\.(jpg|tiff|jpeg|png)/i;
const resizedFolderName = "resized";

const useStyles = createUseStyles({
    app: (theme) => ({
        fontFamily: "Helvetica, sans-serif",
        width: "100vw",
        height: "100%",
        backgroundColor: theme.background,
        display: "flex",
        justifyContent: "center",
        "@media (pointer:none), (pointer:coarse)": {
            flexDirection: "column"
        }
    })
});

const sortBucket = bucket => {
    return bucket.sort((a, b) => {
        let filenameA = a.split(fileTypeRegExp)[0];
        let filenameB = b.split(fileTypeRegExp)[0];

        if (filenameA.includes(resizedFolderName) && !filenameB.includes(resizedFolderName)) {
            return -1;
        }

        if (!filenameA.includes(resizedFolderName) && filenameB.includes(resizedFolderName)) {
            return 1;
        }

        let scaleA = filenameA.split("%")[filenameA.split("%").length - 1];
        let scaleB = filenameB.split("%")[filenameB.split("%").length - 1];

        return scaleA - scaleB;
    });
};

const getTags = (images, setTags) => {
    let keys = Object.values(images)
        .flatMap(imagesInCategory => Object.values(imagesInCategory))
        .map(image => image.key);

    superagent.post("/getTags")
        .send({ keys })
        .set("Content-Type", "application/json")
        .then(res => {
            setTags(res.body);
        });
}

const fetchContent = (setCategories, setImages, setIsLoading) => {
    superagent.get("/getImages")
        .then(res => {
            let images = res.body.images;

            // extract categories
            let categoryNames = [];
            let categories = [];

            images.forEach(image => {
                let extractedCategory = image.signedUrl.split("s3.amazonaws.com/")[1].split("/")[0];

                if (!categoryNames.includes(extractedCategory)) {
                    categoryNames.push(extractedCategory);
                }
            })

            // assign images to categories
            let imagesByCategory = {};

            categoryNames.forEach(categoryName => {
                let imagesForCategory = [];
                images.forEach(image => {
                    if (image.signedUrl.includes(`${categoryName}/`)) {
                        imagesForCategory.push(image);
                    }
                });

                imagesByCategory[categoryName] = imagesForCategory;
            });

            // go through each category and collect images into buckets
            let bucketizedImageUrlsByCategory = {};

            Object.keys(imagesByCategory).forEach(categoryName => {
                let imagesInCategory = imagesByCategory[categoryName];
                let bucketizedImagesInCategory = {};

                // find the rull-res images, then filter for others like it, and build a url array that
                // gets mapped to the file name
                imagesInCategory.forEach(image => {
                    let filename = image.signedUrl.split(categoryName)[1].split(fileTypeRegExp)[0];

                    if (!filename.includes("%40")) {
                        let urls = [];
                        urls.push(...imagesInCategory.filter(image => image.signedUrl.includes(filename)).map(image => image.signedUrl));
                        urls = sortBucket(urls);

                        bucketizedImagesInCategory[filename] = {
                            key: image.signedUrl.split("?X-")[0].split(".com/")[1],
                            isNew: image.isNew,
                            urls: urls,
                            lastModified: image.lastModified
                        };
                    }
                });

                categories.push({
                    name: categoryName,
                    newCount: Object.values(bucketizedImagesInCategory).filter(imageInCategory => imageInCategory.isNew).length
                });

                bucketizedImageUrlsByCategory[categoryName] = bucketizedImagesInCategory;
            });

            setCategories(categories);
            setImages(bucketizedImageUrlsByCategory);
            setIsLoading(false);
        })
        .catch(err => {
            console.log(err);
        });
};

const App = (props) => {
    const classes = useStyles(props.theme);

    const [isLoading, setIsLoading] = useState(true);

    const [categories, setCategories] = useState([]);
    const [images, setImages] = useState({});
    const [tags, setTags] = useState({});

    const [lastFetched, setLastFetched] = useState(-1);
    const [galleryMode, setGalleryMode] = useState(GalleryMode.TILED);

    useEffect(() => {
        setLastFetched(Date.now());
        fetchContent(setCategories, setImages, setIsLoading);
    }, []);

    useEffect(() => {
        if (Object.keys(images).length > 0) {
            getTags(images, setTags);
        }
    }, [images])

    const refresh = () => {
        if (Date.now() - lastFetched > 60000 * 15) {
            setLastFetched(Date.now());
            fetchContent(setCategories, setImages);
        }
    };

    const layout = (
        <Fragment>
            <Navbar galleryMode={galleryMode} setGalleryMode={setGalleryMode} categories={categories} isLoading={isLoading} refresh={refresh} {...props} />
            {galleryMode === GalleryMode.TILED ?
                <TiledGallery images={images} categories={categories} tags={tags} refresh={refresh} isLoading={isLoading} {...props} />
                :
                <SingleGallery images={images} categories={categories} tags={tags} refresh={refresh} isLoading={isLoading} {...props} />
            }
        </Fragment>
    );

    const aboutLayout = (
        <Fragment>
            <Navbar categories={categories} refresh={refresh} {...props} />
            <About {...props} />
        </Fragment>
    );

    return (
        <Div100vh>
            <div className={classes.app}>
                <BrowserRouter>
                    <Routes>
                        <Route path={"/about"} element={aboutLayout}/>
                        <Route path={"/gallery/:category"} element={layout}/>
                        <Route path={"/"} element={layout}/>
                        <Route path={"*"} element={layout}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </Div100vh>
    );
};

export const ThemedApp = () => {
    return <App theme={theme} />;
};
