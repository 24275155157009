import React from "react";
import "./Loader.css";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    themedLoader: theme => ({
        "&:after, &:before": {
            background: theme.accent
        }
    })
})

export const Loader = (props) => {
    const classes = useStyles(props.theme);

    return <div className={`${classes.themedLoader} loader`}></div>;
}