import React, { useState, useEffect } from 'react';
import { createUseStyles } from "react-jss";
import { BsArrowUpRight } from "react-icons/bs"

const useStyles = createUseStyles({
    opener: theme => ({
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        position: "absolute",
        width: theme.padding,
        height: theme.padding,
        bottom: 0,
        right: 0,
        backgroundColor: theme.background,
        color: theme.accent,
        border: `1px ${theme.accent} solid`,
        borderRadius: "100%",
        opacity: 0,
        cursor: "pointer",
        transition: "opacity 300ms ease-in-out, color 300ms ease-in-out, background-color 300ms ease-in-out",
        "&:hover": {
            backgroundColor: theme.accent,
            color: theme.background
        }
    }),
    imageContainer: theme => ({
        userSelect: "none",
        position: "relative",
        marginBottom: theme.spacing * 2,
        transition: "padding 300ms ease-in-out, opacity 1000ms ease-in-out",
        opacity: 1,
        "&:hover, &:active": {
            "& $opener, & $badgeContainer": {
                opacity: 1
            },
            paddingBottom: theme.padding + theme.spacing * 2,
            "&$imageContainerFullSize": {
                paddingBottom: 0
            }
        },
        "&.loading": {
            opacity: 0
        }
    }),
    imageContainerFullSize: {
        userSelect: "none",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& $image": {
            userSelect: "none",
            height: "75%",
            width: "auto"
        },
    },
    image: {
        userSelect: "none",
        width: "100%",
        height: "auto",
        margin: 0,
        boxShadow: "#00000006 10px 10px 10px",
        position: "relative",
        imageRendering: "pixelated",
        transition: "border 300ms ease-in-out"
    },
    badgeContainer: theme => ({
        display: "flex",
        position: "absolute",
        gap: theme.spacing,
        left: 0,
        opacity: 0,
        bottom: theme.spacing,
        transition: "opacity 300ms ease-in-out",
    }),
    badge: theme => ({
        fontWeight: 800,
        fontSize: 10,
        height: 18,
        padding: theme.spacing / 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&.primary": {
            color: theme.background,
            backgroundColor: theme.accent,
        },
        "&.secondary": {
            color: theme.background,
            backgroundColor: theme.secondary,
        },
        "&.tertiary": {
            color: theme.background,
            backgroundColor: theme.tertiary,
        }
    })
});

export const ProgressiveImage = props => {
    const limit = !!props.limit ? props.limit : 0;
    const [index, setIndex] = useState(props.fullSize ? props.urls.length - 1 - limit : 0);
    const [imageTags, setImageTags] = useState({});
    const [loading, setLoading] = useState(true && !props.fullSize);
    const imageRef = React.createRef();

    useEffect(() => {
        const imageTagsAsObject = {};
        !!props.imageTagsArray && props.imageTagsArray.length > 0 && props.imageTagsArray.forEach(tag => {
            imageTagsAsObject[tag.Key] = tag.Value;
        });
        setImageTags(imageTagsAsObject);
    }, [props.imageTagsArray]);

    useEffect(() => {
        setIndex(props.fullSize ? props.urls.length - 1 - limit : 0);
        props.refresh();
    }, [props.urls]);

    useEffect(() => {
        if (imageRef.current && imageRef.current.complete) {
            if (index < props.urls.length - 1 - limit) {
                setIndex(index + 1);
            }

            setLoading(false);
        }
    }, [index]);

    const classes = useStyles(props.theme);

    return (
        <div className={`${classes.imageContainer} ${props.fullSize ? classes.imageContainerFullSize : ""} ${loading ? "loading" : ""}`}>
            {!props.fullSize && <div className={classes.badgeContainer}>
                {props.isNew && <div className={`${classes.badge} primary`}>NEW</div>}
                {imageTags && imageTags.camera && <div className={`${classes.badge} secondary`}>{imageTags.camera}</div>}
                {imageTags && imageTags.location && <div className={`${classes.badge} tertiary`}>{imageTags.location}</div>}
            </div>}
            {!props.fullSize && <a className={classes.opener} href={props.urls[props.urls.length - 1]} target={"_"}>
                <BsArrowUpRight />
            </a>}
            <img className={classes.image}
                 ref={imageRef}
                 src={props.urls[index]}
                 title={props.name}
                 alt={props.name}
                 onLoad={() => {
                     if (index === 0) {
                         setLoading(false);
                     }

                     if (index < props.urls.length - 1 - limit) {
                         setIndex(index + 1);
                     }
                 }}
            />
        </div>
    );
};
