import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";

import { ProgressiveImage } from "../../components"
import { Loader } from "../../components/Loader";

const INITIAL_COUNT = 16;
export const MIN_WIDTH = 400;
export const MAX_COLUMN_COUNT = 4;

const detectScroll = (event, scrollDistance, loadFunction) => {
    const threshold = 0.99;

    let newScrollDistance = event.target.scrollTop / (event.target.scrollHeight - event.target.clientHeight);

    if (!!scrollDistance.current && newScrollDistance >= threshold && scrollDistance.current < threshold) {
        loadFunction();
    }

    scrollDistance.current = newScrollDistance;
};

const loadNext = (count, setCount, nColumns) => () => {
    setCount(count + (nColumns * 2));
}

const reset = (galleryRef, setCount, scrollDistance) => () => {
    setCount(INITIAL_COUNT);

    if (!!scrollDistance.current) {
        scrollDistance.current = 0;
    }

    if (!!galleryRef.current) {
        galleryRef.current.scrollTop = 0;
    }
};

const generateColumns = (images, nColumns) => {
    let columns = [];

    for (let i = 0; i < nColumns; i++) {
        columns.push([]);
    }

    if (!!images) {
        for (let i = 0; i < Object.keys(images).length; i++) {
            columns[i % nColumns].push(Object.values(images)[i]);
        }
    }

    return columns;
};

const calcNColumns = ref => {
    let count = MAX_COLUMN_COUNT;

    while (ref.current.clientWidth / count < MIN_WIDTH && count > 1) {
        count--;
    }

    return count;
};

const useStyles = createUseStyles({
    tiledGallery: theme => ({
        height: "100vh",
        width: "100%",
        maxWidth: MIN_WIDTH * MAX_COLUMN_COUNT + 2 * theme.padding,
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing * 2,
        padding: theme.padding,
        position: "relative",
        overflow: "scroll",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "@media (pointer:none), (pointer:coarse)": {
            paddingTop: theme.padding
        },
        "&.loading": {
            "& $column": {
                opacity: 0,
            },
            "& $loaderContainer": {
                opacity: 1
            }
        }
    }),
    column: (theme) => ({
        position: "relative",
        height: "auto",
        flex: "1",
        opacity: 1,
        transition: "opacity 1000ms ease-in-out",
        "&:after": {
            content: '""',
            display: "block",
            position: "relative",
            height: theme.padding,
            width: "100%"
        }
    }),
    loaderContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        transition: "opacity 1000ms ease-in-out",
        pointerEvents: "none"
    }
});

export const TiledGallery = (props) => {
    const galleryRef = React.createRef();

    const [nColumns, setNColumns] = useState(1);
    const [count, setCount] = useState(INITIAL_COUNT);
    let scrollDistance = useRef(0);

    useEffect(reset(galleryRef, setCount, scrollDistance), [useParams().category]);

    const columnListener = () => {
        if (!!galleryRef.current) {
            setNColumns(calcNColumns(galleryRef));
        }
    };

    window.addEventListener("resize", columnListener);
    useEffect(columnListener, []);

    const category = useParams().category;

    const images = props.images[!!category ? category : !!props.categories[0] ? props.categories[0].name : null];
    const paginatedImages = {};
    !!images && Object.keys(images).forEach((key, index) => {
        if (index < (count + 1)) {
            paginatedImages[key] = images[key];
        }
    });

    const columns = generateColumns(paginatedImages, nColumns);

    const classes = useStyles(props.theme);

    return (
        <div className={`${classes.tiledGallery} ${props.isLoading ? "loading" : ""}`}
             onScroll={event => detectScroll(event, scrollDistance, loadNext(count, setCount, nColumns))}
             ref={galleryRef}>
            {
                columns.map((imagesForColumn, columnIndex) => {
                    return (
                        <div
                            className={classes.column}
                            key={columnIndex}
                        >
                            {imagesForColumn.map((imageForColumn) => {
                                return (<ProgressiveImage
                                    key={imageForColumn.key}
                                    imageTagsArray={props.tags[imageForColumn.key]}
                                    name={imageForColumn.key}
                                    urls={imageForColumn.urls}
                                    isNew={imageForColumn.isNew}
                                    {...props} />);
                            })}
                        </div>
                    );
                })
            }
            <div className={classes.loaderContainer}>
                <Loader {...props} />
            </div>
        </div>
    );
};
