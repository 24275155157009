import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { IoOpenOutline } from "react-icons/io5";

import { aboutPageTitle, welcomeText, websiteUrl, websiteText } from "../../../config";
import { MAX_COLUMN_COUNT, MIN_WIDTH } from "../TiledGallery/TiledGallery";

const useStyles = createUseStyles({
	welcomeTextContainer: (theme) => ({
		height: "100vh",
		width: "auto",
		marginRight: "auto",
		display: "flex",
		flexDirection: "column",
		paddingLeft: theme.padding,
		paddingRight: theme.padding,
		paddingTop: theme.padding,
		color: theme.foreground,
		maxWidth: MIN_WIDTH * MAX_COLUMN_COUNT / 2 + 2 * theme.padding,
		"& h2": {
			fontWeight: 400,
			marginBottom: theme.padding
		}
	}),
	linkContainer: (theme) => ({
		display: "flex",
		alignItems: "center",
		color: theme.foreground,
		transition: "color 300ms ease-in-out",
		"&:hover": {
			color: theme.accent
		},
		"& svg": {
			marginLeft: theme.spacing / 2
		}
	})
});

export const About = props => {
	const classes = useStyles(props.theme);

	return (
		<div className={classes.welcomeTextContainer}>
			<h2>{aboutPageTitle}</h2>
			{welcomeText.map((paragraph, index) => <Fragment key={index}><p>{paragraph}</p><br /></Fragment>)}
			{!!websiteUrl && <a href={websiteUrl} target={"_blank"} className={classes.linkContainer}>
				<p>{ websiteText }</p>
				<IoOpenOutline/>
			</a>}
		</div>
	);
}