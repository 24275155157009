import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"

import { ProgressiveImage } from "../../components"

export const SingleGallery = (props) => {
    const category = useParams().category;
    const images = props.images[!!category ? category : props.categories[0].name];
    const [index, setIndex] = useState(0);

    useEffect(() => {
        setIndex(0);
    }, [category]);

    const useStyles = createUseStyles({
        singleGallery: theme => ({
            height: "100vh",
            width: "100%",
            maxWidth: 1600 + 2 * theme.padding,
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            padding: theme.padding,
            position: "relative",
            overflow: "hidden",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                display: "none"
            },
            "@media (pointer:none), (pointer:coarse)": {
                paddingTop: theme.padding
            }
        }),
        sequence: {
            height: "calc(100% - 48px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        index: theme => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            height: "80px",
            width: "100%",
            color: theme.foreground,
            bottom: 0,
            left: 0
        }),
        controller: theme => ({
            cursor: "pointer",
            display: "flex",
            alignItems: "flex-end",
            position: "absolute",
            height: "100%",
            width: "50%",
            bottom: 0,
            "&.backward": {
                left: 0,
                justifyContent: "flex-end"
            },
            "&.forward": {
                right: 0,
                justifyContent: "flex-start"
            },
            "& svg": {
                color: theme.foreground,
                margin: theme.padding,
                marginBottom: "32px",
                transition: "color 300ms ease-in-out"
            },
            "&:hover": {
                "& svg": {
                    color: theme.accent
                }
            }
        }),
        thumbnail: {
            display: "flex",
            height: "48px",
            width: "48px",
            alignItems: "center",
            justifyContent: "center"
        },
        thumbnailContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: "48px",
            width: "100%",
            overflow: "scroll"
        }
    });

    const classes = useStyles(props.theme);

    const imageCount = !!images ? Object.values(images).length : 0;
    const image = !!images ? Object.values(images)[index % imageCount] : null;

    console.log(props.tags);

    return (
        <div className={classes.singleGallery}>
            <div className={classes.index}>
                {`${imageCount === 0 ? index : index + 1}/${imageCount}`}
            </div>
            {index > 0 &&
                <div className={`${classes.controller} backward`} onClick={() => setIndex(index - 1)}>
                    <BsArrowLeft />
                </div>
            }
            {index < imageCount - 1 &&
                <div className={`${classes.controller} forward`} onClick={() => setIndex(index + 1)}>
                    <BsArrowRight />
                </div>
            }
            <div className={classes.sequence}>
                {image && <ProgressiveImage
                    key={image.key}
                    urls={image.urls}
                    fullSize
                    {...props} />}
            </div>
        </div>
    );
};
