export const theme = {
	margin: 32,
	padding: 32,
	spacing: 8,
	background: "#f6f6f6",
	foreground: "#1a261a",
	accent: "#7a895f"
};

export const title = "Anya Carion Photography";
export const welcomeText = [
	""
];
export const aboutPageTitle = "About";
export const websiteText = "Personal Blog";
export const websiteUrl = "https://www.unexpectedoccurrence.com";
