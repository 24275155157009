import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { MdPhoto, MdPhotoLibrary } from "react-icons/md"
import { BsArrowBarRight } from "react-icons/bs"

import { title } from "../../../config";
import { GalleryMode } from "../App/App"

const useStyles = createUseStyles({
    linkContainer: theme => ({
        position: "relative",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: theme.spacing,
        transition: "opacity 300ms ease-in-out",
        "@media (pointer:none), (pointer:coarse)": {
            marginRight: theme.spacing,
            "&:first-child": {
                marginLeft: theme.margin + 2
            },
            "&:last-child": {
                marginBottom: theme.spacing,
                marginRight: theme.margin
            }
        }
    }),
    linkLabel: theme => ({
        color: theme.background,
        backgroundColor: theme.accent,
        fontWeight: 800,
        fontSize: 10,
        padding: `${theme.spacing / 2}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 1000,
        "@media (pointer:none), (pointer:coarse)": {
            display: "none",
        }
    }),
    link: (theme) => ({
        color: theme.foreground,
        fontWeight: 400,
        position: "relative",
        cursor: "pointer",
        opacity: 1,
        width: "100%",
        "&:last-child": {
            marginBottom: 0,
        },
        "&.active": {
            color: theme.accent,
            opacity: 1,
            "&:before": {
                opacity: 1
            }
        },
        "@media (hover: hover)": {
            "&:hover": {
                color: theme.accent,
                opacity: 1
            }
        },
        "&:before": {
            content: '""',
            display: "block",
            width: 2,
            height: "100%",
            backgroundColor: theme.accent,
            position: "absolute",
            left: -1 * theme.padding / 2 + 1,
            top: 0,
            borderRadius: 1,
            opacity: 0,
            transition: "opacity 300ms ease-in-out, background-color 300ms ease-in-out"
        },
        "@media (pointer:none), (pointer:coarse)": {
            "&.active": {
                color: theme.accent,
            },
            "&:before": {
                display: "none"
            }
        }
    }),
    navbar: (theme) => ({
        width: "250px",
        height: "100vh",
        display: "flex",
        flexShrink: 0,
        flexDirection: "column",
        paddingTop: theme.padding,
        paddingLeft: theme.padding,
        marginLeft: 0,
        position: "relative",
        overflow: "hidden",
        transition: "margin-left 300ms ease-in-out, margin-top 300ms ease-in-out",
        "&.closed": {
            marginLeft: `calc(-1 * (250px - ${1 * theme.padding}px - 20px))`,
            "& $linkContainer, & $title": {
                opacity: 0,
                pointerEvents: "none"
            },
            "& $navbarButtonContainer": {
                bottom: `calc(100% - ${theme.padding}px - 50px)`,
                "@media (pointer:none), (pointer:coarse)": {
                    bottom: theme.padding
                }
            },
            "& $navbarButton": {
                "&.rotating svg": {
                    transform: "rotate(0deg)",
                },
                "@media (hover: hover)": {
                    "&:hover": {
                        backgroundColor: theme.accent
                    }
                },
                "@media (pointer:none), (pointer:coarse)": {
                    "&.rotating svg": {
                        transform: "rotate(90deg)"
                    }
                }
            },
            "@media (pointer:none), (pointer:coarse)": {
                marginLeft: 0,
                marginTop: `calc(-1 * (136px - ${2 * theme.padding}px - 24px))`
            }
        },
        "@media (pointer:none), (pointer:coarse)": {
            padding: 0,
            paddingTop: theme.padding,
            height: 136,
            width: "100vw"
        }
    }),
    title: (theme) => ({
        fontWeight: 400,
        color: theme.foreground,
        marginBottom: theme.margin,
        position: "relative",
        transition: "opacity 300ms ease-in-out, color 300ms ease-in-out",
        "& h2": {
            fontWeight: 400
        },
        "& span": {
            fontWeight: 700
        },
        "&:before": {
            content: '""',
            display: "block",
            width: 2,
            height: "100%",
            backgroundColor: theme.accent,
            position: "absolute",
            left: -1 * theme.padding / 2 + 1,
            top: 0,
            borderRadius: 1,
            opacity: 0,
            transition: "opacity 300ms ease-in-out"
        },
        "@media (hover: hover)": {
            "&:hover": {
                color: theme.accent
            }
        },
        "&.active": {
            color: theme.accent,
            "&:before": {
                opacity: 1
            }
        },
        "@media (pointer:none), (pointer:coarse)": {
            marginLeft: theme.margin + 1,
            marginBottom: theme.padding / 2,
            height: 56,
            maxWidth: `calc(100vw - ${3 * theme.margin}px - 20px)`,
            marginRight: "auto",
            overflow: "hidden"
        }
    }),
    navbarButton: (theme) => ({
        height: 20,
        width: 20,
        backgroundColor: theme.foreground,
        borderRadius: "100%",
        cursor: "pointer",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "background-color 300ms ease-in-out",
        "&#galleryModeSelector": {
            marginTop: theme.spacing,
            "@media (pointer:none), (pointer:coarse)": {
                display: "none"
            }
        },
        "@media (hover: hover)": {
            "&:hover": {
                backgroundColor: theme.accent
            }
        },
        "& svg": {
            width: "12px",
            height: "12px",
            color: theme.background,
        },
        "&.rotating svg": {
            transform: "rotate(180deg)",
            transition: "color 300ms ease-in-out, transform 300ms ease-in-out"
        },
        "@media (pointer:none), (pointer:coarse)": {
            "&.rotating svg": {
                transform: "rotate(-90deg)"
            }
        }
    }),
    navbarButtonContainer: theme => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: 0,
        bottom: theme.margin,
        transition: "bottom 300ms ease-in-out",
        "@media (pointer:none), (pointer:coarse)": {
            right: theme.padding,
            bottom: `calc(100% - ${theme.padding}px - 25px)`,
        }
    }),
    listContainer: theme => ({
        display: "flex",
        height: "auto",
        position: "relative",
        flexDirection: "column",
        alignItems: "flex-start",
        opacity: 1,
        transition: "opacity 1000ms ease-in-out",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "@media (pointer:none), (pointer:coarse)": {
            flexDirection: "row",
            overflow: "scroll"
        },
        "&.loading": {
            opacity: 0
        }
    })
});

export const Navbar = (props) => {
    const [visibility, setVisibility] = useState(true);
    let location = useLocation();
    let categoryParam = useParams().category;

    if (!categoryParam && location.pathname !== "/about") {
        categoryParam = !!props.categories[0] ? props.categories[0].name : null;
    }

    const classes = useStyles(props.theme);

    let titleTokens = title.split(" ");

    let titleClass = classes.title;
    if (location.pathname === "/about") {
        titleClass += " active";
    }

    let titleElement = titleTokens.length > 1 ? (
        <Link to={"/about"} className={titleClass}>
            <h2>{`${titleTokens.slice(0, titleTokens.length - 1).join(" ")} `}<span>{titleTokens[titleTokens.length - 1]}</span></h2>
        </Link>
    ) : (
        <h2 className={classes.title}>
            <span>{title}</span>
        </h2>
    );

    return (
        <div className={`${classes.navbar} ${visibility ? "open" : "closed"}`}>
            {titleElement}
            {!!props.galleryMode && <div className={classes.navbarButtonContainer}>
                <div className={`${classes.navbarButton} rotating`} onClick={() => setVisibility(!visibility)}>
                    <BsArrowBarRight/>
                </div>
                <div id={"galleryModeSelector"} className={classes.navbarButton}
                     onClick={() => props.galleryMode === GalleryMode.TILED ? props.setGalleryMode(GalleryMode.SINGLE) : props.setGalleryMode(GalleryMode.TILED)}>
                    {props.galleryMode === GalleryMode.TILED ? <MdPhoto/> : <MdPhotoLibrary/>}
                </div>
            </div>}
            <div className={`${classes.listContainer} ${props.isLoading ? "loading" : ""}`} >
                {props.categories.map(category => {
                    let linkClass = classes.link;

                    if (category.name === categoryParam) {
                        linkClass += " active";
                    }

                    return (
                        <div key={category.name} className={classes.linkContainer}>
                            <Link className={linkClass} to={`/gallery/${category.name}`} onClick={props.refresh}>{category.name === "facade" ? "façade" : category.name}</Link>
                            {category.newCount !== 0 && <p className={classes.linkLabel}>{`${category.newCount} NEW`}</p>}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
